import React, { Component } from 'react'

import Name from './Name'
import Email from './Email'
import Role from './Role'
import LinkBar from './LinkBar'

import styles from '../css/Home.module.css'


class Home extends Component {
    render() {
        return (
            <div className={styles.main}>
                <Name />
                <div className={styles.center}>
                    <Role />
                    <Email />
                    <LinkBar />
                </div>
            </div>
        )
    }
}

export default Home
