import React, { Component } from 'react'

import styles from '../css/Email.module.css'

class Email extends Component {
    render() {
        return (
            <div className={styles.main}>
                <a 
                    href="mailto:ronca@diag.uniroma1.it"
                    className={styles.email}
                >
                    ronca@diag.uniroma1.it
                </a>
            </div>
        )
    }
}

export default Email
