import React, { Component } from 'react'

import styles from '../css/RoleLink.module.css'

class RoleLink extends Component {
    render() {
        return (
            <a href={this.props.href}
                className={[styles.main,this.props.className].join(' ')} 
            >
                {this.props.children}
            </a>
        )
    }
}

export default RoleLink
