import React, { Component } from 'react'

class LinkBarItem extends Component {
    render() {
        return (
            <a href={this.props.href}>
                {this.props.children}
            </a>
        )
    }
}

export default LinkBarItem
