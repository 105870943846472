import React, { Component } from 'react'

import RoleLink from './RoleLink'

import styles from '../css/Role.module.css'

class Role extends Component {
    render() {
        return (
            <div className={styles.main}>
                <span className={styles.avoidWrap}>
                    <span className={styles.position}>
                        Postdoctoral Researcher&nbsp;
                    </span>
                </span>
                <span className={styles.avoidWrap}>
                    in&nbsp;
                    <span className={styles.topic}>
                        Artificial Intelligence&nbsp;
                    </span> 
                </span>
                <span className={styles.affiliation}>
                    <span className={styles.avoidWrap}>
                        at&nbsp;
                        <RoleLink
                            href="https://www.uniroma1.it" 
                            className={styles.sapienzaLink} 
                        >
                            Sapienza Università di Roma
                        </RoleLink> 
                        &nbsp;&ndash;&nbsp;
                        <RoleLink
                            href="https://www.diag.uniroma1.it" 
                            className={styles.diagLink} 
                        > DIAG
                        </RoleLink>
                    </span>
                </span>
            </div>
        )
    }
}

export default Role
