import React, { Component } from 'react'

import styles from '../css/Name.module.css'


class Name extends Component {
    render() {
        return (
            <div className={styles.main}>
                <div className={styles.name}>Alessandro Ronca</div>
            </div>
        )
    }
}

export default Name
