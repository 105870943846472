import React, { Component } from 'react'

import LinkBarItem from './LinkBarItem'

import styles from '../css/LinkBar.module.css'


class LinkBar extends Component {
    render() {
        return (
            <div className={styles.main}>
                <LinkBarItem 
                    href="https://dblp.uni-trier.de/pers/hd/r/Ronca:Alessandro"
                >
                    DBLP
                </LinkBarItem>
                <LinkBarItem 
                    href="https://scholar.google.com/citations?user=0NwK2-IAAAAJ"
                    target="_blank"
                >
                    Google Scholar
                </LinkBarItem>
                <LinkBarItem 
                    href="https://orcid.org/0000-0002-0131-2087"
                >
                    ORCID
                </LinkBarItem>
            </div>
        )
    }
}

export default LinkBar
